/*mixins*/
@mixin max-width($x){
  @media screen and (max-width: $x + px){
    @content;
  }
}
@mixin transition{
  transition: all 300ms;
}
@mixin box-shadow{
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.28);
}
/*vars*/
$black: #010101;
$gray: #d6d6d6;
$light-gray: #ededed;
$white: white;
$orange: #f9593a;
$yellow: #ffd200;

.wrapper{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .page-sizer{
    flex: 1 1 auto;
  }
}
.nav-tabs{
  padding-bottom: 0;
}
.product-tabs{
  position: relative;
  .product-tabs-navigator__item{
    margin: 0;
    &.active{
      border: 1px solid $gray;
    }
  }
  .product-tabs-navigator{
    border-bottom: 1px solid $gray;
    position: relative;
    li{
      a{
        padding: 20px 15px !important;
      }
      @include max-width(500){
        display: none;
      }
      &:first-child{
        display: block !important;
      }
      &.product-tabs-panel__btn{
        display: none;
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 0;
        height: 100%;
        width: 10px;
        @include max-width(500){
          display: block !important;
        }
        span{
          position: absolute;
          display: block;
          width: 10px;
          height: 10px;
          background: #303030;
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
          &:after{
            content: '';
            position: absolute;
            top: -15px;
            left: 0;
            display: block;
            width: 10px;
            height: 10px;
            background: #303030;
            border-radius: 50%;
          }
          &:before{
            content: '';
            position: absolute;
            top: 15px;
            left: 0;
            display: block;
            width: 10px;
            height: 10px;
            background: #303030;
            border-radius: 50%;
          }
        }
      }
    }
  }
  &-panel{
    display: none;
    position: absolute;
    right: 0;
    top: 60px;
    background: $white;
    border: 1px solid #d6d6d6;
    @include box-shadow;
    border-radius: 5px;
    padding: 15px;
    box-sizing: border-box;
    ul{
      li{
        display: block;
        margin-bottom: 5px !important;
        a{
          color: $black;
          font-size: 13px;
          font-weight: 700;
        }
      }
    }
    @include max-width(500){
      /* display: block;*/
    }
  }
}
.product-main-cell_info{
  @include max-width(768){
    max-width: 320px;
    margin: 0 auto;
    padding: 0;
  }
}
.product-delivery{
  max-width: 195px;
  @include max-width(768){
    max-width: 320px;
  }
  @include max-width(500){
    max-width: 100% !important;
  }
  /*.product-tabs-navigator__item.active {
    border: 1px solid $gray;
  }
  .product-tabs-navigator {
    border-bottom: 1px solid $gray;
  }*/
  &__title{
    display: flex;
    margin-bottom: 5px;
    @include max-width(500){
      display: none;
    }
  }
  .nav-tabs{
    display: flex;

    li{

      margin-bottom: 0px;
      @include max-width(500){
        width: auto;
      }

      a{
        padding: 10px 15px;
        color: #858585 !important;
      }
      &.active{
        margin-bottom: -1px;
        border-bottom: 1px solid white;
        &:after{
          content: '';
          bottom: -1px;
        }
        @include max-width(992){
          margin-bottom: -1px;

        }
        a{
          color: $orange !important;
        }

      }
    }

  }
  .product-tabs-item{
    padding: 10px;
  }
}
.all-payment-link{
  color: $orange !important;
  display: inline-block;
  vertical-align: middle;
  font-size: 11px;
  text-decoration: underline;
  text-transform: none;
  white-space: nowrap;
  margin-left: 10px;
}
.payment-row{
  display: flex;
  flex-wrap: wrap;
  &__el{
    /* width: 33.3333%;*/
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      max-width: 100%;
      height: auto;
    }
  }
}
/*кнопка еще*/
.header-phones-more{
  &__title{
    @include max-width(992){
      padding: 6px 13px;
    }
    @media only screen and (min-width: 768px){
      background: $white !important;
      border: 1px solid $orange;
      border-radius: 21px !important;
      padding: 6px 20px;
      height: 25px;
      white-space: nowrap;
      font-size: 9px;
      font-weight: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        padding-right: 5px;
        &:before{
          position: absolute;
          right: -6px !important;
          top: 50% !important;
          color: $orange !important;
          font-size: 18px !important;
          line-height: 10px;
          display: inline-block;
          transform: translateY(-50%);
        }
      }
    }
  }
  @include max-width(992){
    margin: 0;

  }
}
.header-phones-more-box{
  &:before{
    content: '';
    display: none;
  }
}
.product-main-cell_delivery{
  @include max-width(1200){
    width: initial;
    .product-delivery{
      max-width: 400px;
    }
  }
  @include max-width(992){
    display: block !important;
    .product-delivery{
      margin: 0 auto;
    }
  }

}
.product-tabs-navigator__item.active{
  border: 1px solid $gray;
  @include max-width(992){
    border-bottom: 2px solid white;
    margin-bottom: -2px;
  }

}
.productsitem__stock{
  display: none !important;
}
.product-info__stock{
  background: none !important;
  padding: 0;
  display: none;
}
.product-tabs-navigator{
  border-bottom: 2px solid #cdcdcd;
}
.product-body .productsitem-params{
  display: flex;
  z-index: 10;
  flex-wrap: wrap;
  &__item{
    padding: 0;
    color: #010101;
    line-height: 30px;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    height: 34px;
    -webkit-box-shadow: 1px 3px 5px rgba(115, 119, 121, 0.14);
    box-shadow: 1px 3px 5px rgba(115, 119, 121, 0.14);
    border-radius: 4px;
    border: 2px solid #d6d6d6;
    background-color: #ffffff;
    display: inline-block;
    min-width: 50px;
    overflow: hidden;
    margin-left: 0px;
    margin-right: 6px;
    margin-bottom: 5px;
    span{
      width: inherit !important;
      padding: 0 15px;
      box-sizing: border-box;
    }
    &:before{
      content: '';
      display: none;
    }
  }
}
.productsitem{
  overflow: visible;
}
.btn-no-available{
  position: relative;
  width: 100%;
  height: 44px;
  border: 2px solid #d6d6d6;
  background-color: #ffffff;
  color: #d6d6d6 !important;
  text-transform: uppercase;
  font-size: 14px;
  padding-left: 20px;
  box-sizing: border-box;
  border-radius: 21px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  &:after{
    content: url(../img/card/close.png);
    position: absolute;
    left: 15px;
    top: 11px;

  }

}
.cart-table{
  .trow{
    .cell{
      color: black;
      &.label{
        position: relative;
        &:after{
          content: attr(data-label);
          position: absolute;
          font-size: 12px;
          color: #9c9c9c;
          top: -18px;
          white-space: nowrap;
          text-transform: uppercase;
          font-weight: 500;
          transform: translateX(-50%);
          left: 50%;
          @include max-width(768){
            display: none;
          }
        }
      }

    }
  }

}
/*header*/
.bordered{
  border: 1px solid $light-gray;
  border-radius: 50%;
}
.header__logo, .header-cell_logo{
  width: auto;
}
.header-switcher{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 0 25px;
  @include max-width(992){
    margin: 0 10px;

  }
  &__btn{
    font-family: "Roboto Slab", sans-serif;
    text-transform: uppercase;
    font-size: 11px;
    color: $light-gray;
    cursor: pointer;
    &--active{
      color: $black;
    }
  }
  .checkbox-switch-container{
    pointer-events: none;
    margin: 0 10px;
    position: relative;
    width: 50px;
    height: 11px;

  }
  label{
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    input{
      display: none;
    }
  }
  .checkbox-slider{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $light-gray;
    -webkit-transition: .4s;
    transition: .4s;
    &:before{
      content: '';
      position: absolute;
      height: 23px;
      width: 23px;
      left: 0;
      top: 50%;
      background-color: $orange;
      box-shadow: 3px 3px 10px rgba(249, 89, 58, 0.55);
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
      transform: translateX(0) translateY(-50%);
    }
    &.round{
      border-radius: 34px;
    }
  }
  input:checked + .checkbox-slider{
    /*  background-color: $orange;*/
    &:before{
      transform: translateX(27px) translateY(-50%);

    }

  }
}
.h-search{
  input{
    padding: 0 0 0 40px;
    background-color: #fff;
    border-radius: 20px;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    width: 100%;
    border: 1px solid $light-gray;
    box-sizing: border-box;
  }
  &__wrap{
    position: relative;
  }
  &__submit{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 13px;
    height: 16px;
    background: transparent;
    &:after{
      content: url(../img/search-icon.png);

    }
  }
  &__btn{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    &:before{
      content: url(../img/card/mobile-search.png);
      color: $white;
      font-size: 16px;
      text-align: center;
      line-height: 30px;
      display: block;
      top: 2px;
      position: absolute;
      left: 12px;
    }
    &:after{
      content: '';
      display: block;
      height: 20px;
      width: 2px;
      right: -2px;

      background: #e6e6e6;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.header-cell_schedule{
  width: initial;
}
.header-schedule{
  text-align: left;
  p{
    line-height: 15px;
  }
}
.header-top-table{
  background: $white;
  padding: 0 17px;
  box-sizing: border-box;
  border-bottom: 3px solid #e3e4e5;
  > .cell{
    padding: 10px 0;
    box-sizing: border-box;
    height: 57px;
  }
}
.header-under-top{
  margin-right: 272px;
  margin-bottom: 17px;
  height: 40px;
  padding: 0 17px 3px;
  box-sizing: border-box;

  background: rgba(226, 226, 226, 0.478);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .h-search{
    width: 100%;
    max-width: 470px;
  }
  @include max-width(1345){
    width: 100%;
    height: 30px;
  }
;
}
.header-cart-info{
  @include max-width(768){
    display: none;
  }
}
.header-catalog{
  @include max-width(768){
    top: 54px;
    position: relative;
  }

}
.header-catalog-block{

  @include max-width(768){
    padding: 0;
    .header-catalog-list{

      li{
        padding-top: 15px;
        padding-bottom: 15px;
        box-sizing: border-box;
        border-bottom: 1px solid #eee;
      }
    }
  }
}
.header-search.top{
  @include max-width(768){
    display: none !important;
  }
}
.mobile-menu{
  .header-search{

    &__input{
      padding: 0px 0px 0px 55px;
      background-color: white;
      border-radius: 20px;
      height: 43px;
      line-height: 43px;
    }
    &__btn{

      right: inherit;
      background-color: transparent;
      top: 12px;
      left: 15px;

      &:before{
        content: url(../img/card/mobile-search.png);
      }
      &:after{
        content: '';
        display: block;
        height: 25px;
        width: 2px;
        right: -2px;
        top: 5px;
        background: #e6e6e6;
        position: absolute;
      }
    }
  }
  .header-menu{
    @include max-width(768){
      overflow-x: hidden;
    }

  }
  .header-search-form{
    left: 0;
    width: 100%;
    right: inherit;
    min-height: 47px;
    top: -65px;

  }
  &:after{
    content: '';
    height: 1px !important;
    width: 100% !important;
    max-width: calc(100% - 20px);
  }
}
.header-search-form-content{

  @include max-width(768){
    background: #f1f1f1;
  }
  padding: 7px 11px;
  border-radius: 0;
  box-shadow: none;
  input{
    padding: 0 0 0 55px;
    background-color: #fff;
    border-radius: 20px;
    height: 32px;
    line-height: 32px;
  }
  button{
    right: inherit;
    background-color: transparent;
    top: 6px;
    left: 15px;

    &:before{
      content: url(../img/card/mobile-search.png);
    }
    &:after{
      content: '';
      display: block;
      height: 25px;
      width: 2px;
      right: -2px;
      top: 5px;
      background: #e6e6e6;
      position: absolute;
    }
  }
}
.gotocart:after, button.add-to-cart:after{
  border-left: none;
}
.gotocart:after{

  padding-left: 23px;
}
/*
noscroll ios*/
html.menu{
  overflow: hidden;
  position: relative;
  height: 100%;
  body{
    overflow: hidden;
    position: relative;
    height: 100%;

  }
}
.product-main-params{
  margin-top: 20px;
  position: inherit;
  left: 0;
  z-index: 10;
}
.product-body .gallery .full{
  padding-top: 0;
}
.delivery-tipsy{
  /* min-width:90px;*/
  /* transform: translateX(-20px);*/

  opacity: 1 !important;
  .tipsy-inner{
    border: 2px solid $orange;
    box-shadow: 2px 2px 10px rgba(249, 89, 58, 0.33);
    background: #fff;
    color: #070707 !important;
    font-weight: 700;
    font-size: 11px !important;
    padding: 10px !important;
    box-sizing: border-box;
  }
  .tipsy-arrow{
    /* background: url(../img/icons/arrow-wind-small.png) 0 0 no-repeat;*/
    background: transparent;
    left: 50% !important;
    margin-left: 0 !important;
    transform: rotate(180deg) translateX(50%);
    width: 18px !important;
    height: 11px !important;
    top: -2px !important;
    /*  top: initial !important;*/
    /*  background-size: contain !important;*/
    &:after{
      content: '';
      position: absolute;
      top: 2px;
      left: -3px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 12px 0;
      border-color: $orange transparent transparent;
    }
    &:before{
      content: '';
      position: absolute;
      z-index: 1;
      top: -1px;
      left: -3px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 12px 0;
      border-color: $white transparent transparent;
    }
  }
}
.delivery-tipsy-top{
  /* min-width:90px;*/
  /*  width: 150px;*/
  padding: 5px 0;
  width: 80px;
  opacity: 1 !important;
  .tipsy-inner{
    box-sizing: border-box;
    border: 2px solid $orange;
    box-shadow: 2px 2px 10px rgba(249, 89, 58, 0.33);
    background: #fff;
    color: #070707 !important;
    font-weight: 700;
    font-size: 11px !important;
    padding: 10px 5px !important;

    /*width: 60px;
    margin-top: -8px;
    margin-left: -6px;*/
  }
  .tipsy-arrow{
    /*background: url(../img/icons/arrow-wind-small.png) 0 0 no-repeat;*/
    /* background-size: contain !important;*/
    background: transparent;
    left: 50% !important;
    margin-left: 0 !important;
    transform: translateX(-50%);
    width: 18px !important;
    height: 11px !important;
    bottom: -2px !important;
    top: initial !important;

    &:after{
      content: '';
      position: absolute;
      top: 2px;
      left: -3px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 12px 0;
      border-color: $orange transparent transparent;
    }
    &:before{
      content: '';
      position: absolute;
      z-index: 1;
      top: -1px;
      left: -3px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 12px 0;
      border-color: $white transparent transparent;
    }
  }
}
.tipsy{
  /*  padding: 0;*/
}
.grid-product-body{
  .thumb{
    /*display: inline;*/
    overflow: hidden;
  }
  .productsitem-mainblock{
    min-height: 100px;
    padding: 10px 10px 5px;
    height: initial !important;
    .cell{
      vertical-align: top;
    }
  }
  .productsitem-params{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 10;
    &__item{
      padding: 0 10px;
      opacity: 0.5 !important;
      color: #010101;
      line-height: 30px;
      text-align: left;
      font-weight: 700;
      font-size: 14px;
      height: 34px;
      width: 60px;
      -webkit-box-shadow: 1px 3px 5px rgba(115, 119, 121, 0.14);
      box-shadow: 1px 3px 5px rgba(115, 119, 121, 0.14);
      border-radius: 4px;
      border: 2px solid #d6d6d6;
      background-color: #ffffff;
      display: inline-block;
      min-width: 50px;
      overflow: hidden;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 5px;
      span{
        width: 100% !important;
      }
      &:before{
        content: '';
        display: none;
      }
    }
  }
}
.header-menu{
  overflow: visible;
}
/*main-slider banner*/
@include max-width(1345){
  .mslide-bg-image{
    height: 265px !important;
  }
  .header-catalog{
    min-height: initial !important;

  }
}
.bx-android, .bx-touch, .bx-retina{
  .header-bottom-mainpage{
    .header-catalog{
      min-height: initial;
    }
  }
}
.header-tab{
  .h-search{
    margin: 0 20px;
  }
  .header-cart{
    padding: 10px 0;
    .header-cart-info{
      display: none;
    }
  }
  .header-cell_schedule{
    @include max-width(1200){
      display: none;
    }
  }
  .header-cell_mail{
    @include max-width(992){
      display: none;
    }
  }
}
.header-top-icons{
  @include max-width(768){
    float: right;
  }
}
.header-bottom-mainpage{
  .header-catalog{
    min-height: 515px;
  }
}
.mslider-bg{
  position: relative;
  .mslide-bg-image{
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    width: 100%;
    height: 100%;
  }
}
.carousel-indicators{
  text-align: left;
  margin-left: 0;
  left: 6%;
}
.mslider-banners{
  position: absolute;
  font-size: 20px;
  padding: 13px;
  box-sizing: border-box;
  z-index: 1;
  right: 0;
  width: 300px;
  height: 100%;
  top: 0;
  color: $black;
  display: flex;
  flex-direction: column;

}
.mslider-banner{
  background: $white;
  padding: 20px;
  box-sizing: border-box;
  outline: 5px solid #f5f5f5;
  outline-offset: -5px;
  margin-bottom: 7px;
  @include box-shadow;
  @include max-width(1345){
    &:last-child{
      display: none;
    }
  }
  &__name{
    text-align: center;
  }
  &__img{
    text-align: center;
  }
  &-footer{
    display: flex;
    justify-content: space-between;
    &__old-price{
      font-size: 16px;
      margin-bottom: 5px;
      text-align: center;
      text-decoration: line-through;
    }
    &__new-price{
      font-weight: 900;
      font-size: 24px;
      color: $orange;
      text-align: center;

    }
  }
}
.contact-b{
  display: flex;
  margin: 0 10px;
  @include max-width(992){
    margin: 0 5px;

  }
  &__img{
    flex-shrink: 0;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      max-width: 100%;
    }
  }
  &__info{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }
  &__desc{

    color: #9c9c9c;
    font-size: 9px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  &__text{
    color: #c8c8c8;
    line-height: 12px;
    font-size: 11px;
  }
  &--fs{
    flex-shrink: 0;
  }
  &--black{
    .footer-contacts__value{
      color: $black !important;
    }
    .contact-b{
      &__text{
        color: $black;
      }
    }
  }
}
.product-info{
  .cell{
    @include max-width(500){
      vertical-align: middle;
    }
  }
  .add-to-cart{
    @include max-width(500){

      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background: #f9593a;
      span{
        color: $white;
        background: url(../img/icons/cart_4.svg) left center no-repeat;
      }

    }
  }
}
.product-info-action{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -10px;
  .cell{
    margin: 0 10px 10px;
  }
}
.products-row{
  .productsitem{
    padding: 17px;
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto 20px;
  }
  .grid-product-body{
    flex: 1 1 auto;
  }
  .add-to-cart{
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background: #f9593a;
    span{
      color: $white;
      background: url(../img/icons/cart_4.svg) left center no-repeat;
    }
  }
  .quantity{
    width: 75px;
  }
  .productsitem-actions{
    padding: 0;
    margin: 10px 0 0;
    height: 55px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    /*  flex-wrap: wrap;*/
    box-sizing: border-box;
    align-items: center;
    .cell{
      width: initial;
      vertical-align: middle;
      /*  padding: 0 5px;*/
    }
    .add-to-cart{
      width: auto;
      padding: 0 10px;
    }
  }
  .productsitem__image{
    img{
      max-width: 70px;
      max-height: 70px;
      margin: 0 0 10px;
    }
  }
  .top-infio{
    padding: 0;
  }
  .productsitem-mainblock{
    padding: 0;
    &-footer{
      display: flex;
      align-items: center;
      /*  justify-content: space-between;*/
      flex-wrap: wrap;
      margin-bottom: 10px;

      width: 100%;
      .top-infio{
        margin-right: 10px;
      }
    }
  }
  .prod-details{
    padding: 0;
  }
  .productsitem-params__item{
    line-height: 22px;
    padding: 0 6px;
    height: 25px;
    margin-bottom: 3px;
  }
  .prod-title{

    font-family: "Roboto Slab", sans-serif;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 10px;
    a{
      color: $black;
    }
  }
}
.product-infotable{
  display: flex;

  @include max-width(992){
    flex-wrap: wrap;
  }
}
/*
footer-v2*/
.footer-v2{
  &-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @include max-width(650){
      margin-bottom: 0;
    }

  }
  &-bottom{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 -10px;
    padding-bottom: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #3c3c3c;
    @include max-width(650){
      display: none;
    }
  }
  &-desc{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    box-sizing: border-box;
    margin: 0 -10px;
    @include max-width(650){
      flex-wrap: wrap;
    }
    > *{
      margin: 0 10px !important;
    }
  }
  &-social{
    margin: 0 -5px;
    a{
      margin: 0 5px;
    }
    img{
      width: 25px;
      height: 25px;
    }
    @include max-width(650){
      display: none;
    }
  }

  .footer-menu{
    @include max-width(650){
      display: none;
    }

    ul{
      margin: 0;
      padding: 0;
      display: flex;
      li{
        list-style: none;
        text-transform: uppercase;
        margin-right: 12px;
        color: $white;
        letter-spacing: 1.46px;
        font-weight: 700;
        a{
          @include max-width(1200){
            font-size: 9px;
          }
        }

      }
    }
  }
  .footer-menu-mobile{
    display: none;
    @include max-width(650){
      display: block;
    }

    ul{
      margin: 0;
      padding: 0;
      display: flex;
      li{
        list-style: none;
        text-transform: uppercase;
        margin-right: 12px;
        color: $white;
        letter-spacing: 1.46px;
        font-weight: 700;
        a{
          @include max-width(1200){
            font-size: 9px;
          }
        }

      }
    }
  }
  .footer__copy{
    margin: 0;
    max-width: 670px;
    @include max-width(650){
      order: 1;
      margin-top: 22px !important;
      text-align: center;
    }
  }
  .footer__logo{
    @include max-width(650){
      width: 50%;
    }
  }
  .footer-button{
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    padding: 0px;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include max-width(650){

    }
  }
}
.footer-mobile{
  background: #333333;
  padding: 10px 0;
  box-sizing: border-box;
  display: none;
  @include max-width(650){
    display: block;
  }
  &__row{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-link{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__img{
      /* margin-bottom: 10px;*/
      img{
        max-width: 100%;
        height: auto;
      }
    }
    &__title{
      color: #a1a1a1;
      text-transform: uppercase;
      font-size: 9px;
      font-weight: 700;
      text-align: center;
    }
  }
}
/*
datepicker*/
.date-time-wrapper{
  position: relative;
  &__datetime-fake{
   /* position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;*/
  }
}
.close-bg{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /*   background: #000;*/
  z-index: 99;
}
#express-delivery-hidden{
  /*  position: initial;*/
}
.bootstrap-datetimepicker-widget{
  box-shadow: rgba(1, 1, 1, 0.1) 2px 3px 8px;
  border: 4px solid $orange;
  background-color: rgb(255, 255, 255);
  border-radius: 7px;
  padding: 0 !important;
  margin: 20px 0 !important;
  width: 435px !important;
   @include max-width(440){
     width: 320px !important;
   }
  &:before{
    content: '';
    display: none !important;
  }
  &:after{
    background: url(../img/icons/arrow-wind.png) no-repeat;
    width: 28px;
    height: 19px;
    position: absolute;
    margin-left: -14px;
    border: none !important;
  }
  &.bottom{
    &:after{
      top: -19px !important;
      left: 50% !important;
    }

  }
  &.top{
    &:after{
      transform: rotate(180deg);
      left: 50% !important;
      top: initial;
      bottom: -19px !important;
    }

  }
  table{
    td{
      background-color: transparent !important;
      border-color: transparent !important;
      padding: 5px !important;
      &:before{
        content: '';
        display: none !important;
      }

      /* &.weekend{
         .data-btn{
           border: 1px solid #949494;
           box-shadow: rgba(148, 148, 148, 0.25) 2px 3px 8px;
         }
       }*/

      &.active{
        .data-btn{
          background: $orange !important;
          color: $white !important;
          border: 1px solid $orange;
          font-weight: bold;
          box-shadow: rgba(249, 89, 58, 0.25) 2px 3px 8px;
        }
      }
      &.disabled{
        .data-btn{
          border: none;
          background: none !important;
          box-shadow: none;

        }
      }
      &:hover{
        .data-btn{
          background: $white;
          color: $orange;
          border: 1px solid $orange;
          font-weight: bold;
          box-shadow: rgba(249, 89, 58, 0.25) 2px 3px 8px;
        }

      }
    }
  }
  .data-btn{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 12px;
    display: flex;
    margin: 0 auto;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    border: 1px solid #949494;
    box-shadow: rgba(148, 148, 148, 0.25) 2px 3px 8px;
  }
  [data-action="togglePicker"]{
    display: none;
  }

  .picker-switch{

    color: $orange;
    background: transparent !important;
  }
  .prev, .next{
    background: transparent !important;
    font-size: 9px;
  }
  /*.datepicker-days{
    max-width: 440px;
  }*/
  .datepicker{
    padding: 30px;
  }
  .datepicker-months{
    .year{
      &.active{
        background: $orange !important;
      }
      &.disabled{
        background: transparent !important;
      }
      &:hover{
        background: $orange;

      }
    }
    .month{
      &.active{
        background: $orange !important;
      }
      &.disabled{
        background: transparent !important;
      }
      &:hover{
        background: $orange;

      }
    }
  }
  .datetimepicker-footer{
    display: flex;
    justify-content: space-between;
    margin: 25px -5px 0 !important;
     @include max-width(440){
       flex-wrap: wrap;
       justify-content: center;
       select{
         margin-bottom: 15px;
       }
     }
    > *{
      margin: 0 5px;
    }
    select{
      width: 160px;
    }
    .checkbox{
      display: flex;
      align-items: center;
      label{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 14px ;
        color: $black !important;
        font-size: 14px;
        &:before{
          content: '';
          top: 50%;
          transform: translateY(-50%);
        }
        span{
          font-weight: bold;
        }
      }

      [type=checkbox]:checked + label,
      [type=checkbox]:not(:checked) + label{
        color: #8c8c8c;
        font-size: 14px;
        line-height: 14px;
        &:after{
          top: 50%;
          transform: translateY(-60%) scale(1);
        }
      }
    }
  }
}